// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  --background: var(--ion-color-light-warm);
  display: flex;
  min-height: 80px;
}
.header-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-row .title {
  padding-inline-start: 0;
  padding-inline-end: 0.5rem;
}
.header-row .dealers-category-wrapper {
  width: 100%;
}
.header-row ion-searchbar {
  padding-left: 0;
}
.header-row .switch-search,
.header-row .filter {
  --padding-start: 0.5rem;
  --padding-end: 0.5rem;
}
.header-row .filter ion-icon {
  font-size: 26px;
}

.dealer-slides {
  bottom: 5px;
  max-width: 100%;
  position: absolute;
  width: 100%;
}
.dealer-slides app-dealer-card {
  width: 100%;
}

.content-list {
  --background: transparent;
  max-height: calc(100vh - 80px);
}
.content-list .dealers-not-found {
  background: var(--ion-color-light-warm);
}
.content-list .dealers-list {
  min-height: 100%;
  height: 100%;
  background: var(--ion-color-light-warm);
  contain: none;
}
.content-list .dealers-list .cdk-virtual-scroll-content-wrapper {
  height: 100%;
  min-height: 100%;
}
.content-list .dealers-list ion-list {
  background: transparent;
  height: 100%;
  min-height: 100%;
}
.content-list .dealers-list-item {
  --background: transparent;
  border-radius: 1rem;
}
.content-list .dealers-list-item.active {
  --background: var(--ion-color-light-shade);
}
.content-list .dealers-list-item app-dealer-card {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
