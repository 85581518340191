// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flexbox {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.button--inline {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 400;
  height: auto;
  padding: 0;
  vertical-align: top;
}

.empty {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: flex-start;
}

.slider-buttons {
  display: flex;
  float: right;
  justify-content: space-between;
  margin: 1rem 1.2rem 0 0;
  width: 88px;
}
.slider-button {
  --padding-start: 0.5rem;
  --padding-end: 0.5rem;
  --color: var(--ion-color-dark);
  --background: var(--ion-color-white);
  --background-hover: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --opacity: 1 !important;
  --border-style: none;
  --border-width: 0;
  --box-shadow: none;
  --border-radius: 100%;
  height: 2.4rem;
  margin: 0;
}
.slider-button ion-icon {
  font-size: 1.4rem;
}

ion-slide {
  width: 250px !important;
}
ion-slide .slide-zoom {
  text-align: initial;
}

.result-message {
  font-size: 1.3rem;
  font-weight: 500;
  opacity: 0.5;
}

@media (min-width: 1200px) {
  ion-slide {
    width: 420px !important;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
