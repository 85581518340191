// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.split-pane-md.split-pane-visible > .split-pane-side {
  max-width: 180px;
  min-width: 180px;
}

ion-split-pane {
  --border: none;
}

ion-toolbar,
ion-content {
  --background: var(--ion-color-dark-blue);
  --color: var(--ion-color-brand-contrast);
}

ion-content section {
  display: flex;
  flex-direction: column;
  height: 100%;
}

ion-menu {
  --max-width: 180px;
  --min-width: 180px;
}

ion-list,
ion-item {
  --background: var(--ion-color-dark-blue);
  --background-focused: var(--ion-color-brand-tint);
  --background-activated: var(--ion-color-brand-tint);
  --color: var(--ion-color-brand-contrast);
  background: var(--ion-color-dark-blue);
}

ion-list {
  padding-bottom: 0;
  padding-top: 0;
}

ion-toast.announcement-info {
  --color: #5aacd8;
}
ion-toast.announcement-info .toast-massage {
  color: #5aacd8 !important;
}
ion-toast.announcement-info .toast-button {
  color: #5aacd8 !important;
}
ion-toast.announcement-issue .toast-massage {
  color: #ffab00 !important;
}

a {
  outline: none;
}

.menu-logo {
  display: block;
  height: 30px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
  width: 60px;
}

.menu-copyright {
  background-color: var(--ion-color-dark-blue-active);
  font-size: 0.9rem;
  padding: 1rem;
  text-align: left;
}
.menu-copyright > div:not(:last-of-type) {
  margin-bottom: 1rem;
}

.menu.collapsed {
  --max-width: 70px;
  --min-width: 70px;
  max-width: 70px !important;
  min-width: 70px !important;
}
.menu.collapsed .menu-logo {
  height: 20px;
  transition: all 0.3s ease-in-out;
  width: 40px;
}
.menu.collapsed app-menu-item {
  --display-collapsed: none;
}
.menu.collapsed .menu-copyright {
  display: none;
}

ion-fab-button {
  --box-shadow: none;
}

.menu-header {
  height: 122px;
}

.menu-toolbar {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.menu-footer {
  margin-top: auto;
}

.menu-collapse {
  align-items: center;
  display: flex;
  height: 120px;
  justify-content: center;
}

.terms-of-use a {
  display: block;
  line-height: 1.2;
  text-decoration: none;
}

.version {
  color: var(--ion-color-medium);
}

.modal-wrapper {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
