// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speedometer {
  display: flex;
  flex-flow: row nowrap;
  background-image: url('speedometer_background.f17af8ef56676a26.png');
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.speedometer-marks {
  background-image: url('speedometer_marks.a53e1c61ba69a4d8.png');
  background-origin: border-box;
  background-position: initial;
  background-repeat: no-repeat;
  background-size: contain;
}
.speedometer-marks .speedometer-info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  background-color: rgb(var(--ion-color-darker-blue) 0.3);
}
.speedometer::after {
  background-image: url('speedometer_decoration.0ff270aba089bac4.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 276.9565217391px;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(-4px, 1px);
  width: 100%;
}
.speedometer::before {
  background-image: url('speedometer_shadow.73d4c2a87dc1e61e.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 318.5px;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translate(2px, 0);
  width: 100%;
}
.speedometer .round-param {
  border: 1px solid var(--ion-color-dark-blue-active);
  border-radius: 50%;
  height: 145px;
  position: relative;
  width: 145px;
}
.speedometer .round-param::after {
  border-bottom: 3px solid #23272d;
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: -1px;
  width: 100%;
}
.speedometer .round-param > div:first-of-type {
  border-radius: 50%;
  height: 70%;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  z-index: 2;
}
.speedometer .round-param .value-title {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
}
.speedometer .round-param .value {
  font-size: 1.2rem;
  font-weight: 500;
  white-space: nowrap;
}
.speedometer .round-param .unit {
  font-size: 1rem;
  font-weight: 500;
}
.speedometer .round-param .fuel-level-locked {
  cursor: pointer;
  left: 50%;
  padding: 10px;
  position: absolute;
  top: -32px;
  transform: translateX(-50%);
}
.speedometer .round-param .round-progress {
  left: -1px;
  position: absolute;
  top: -2px;
  transform: rotate(-135deg);
  z-index: 1;
}
.speedometer .round-param .active-fuel {
  bottom: 10px;
  position: absolute;
  z-index: 3;
}
.speedometer .round-param .round-buttons {
  margin-top: 0.3rem;
}
.speedometer .round-param .round-buttons .button-wrapper {
  opacity: 0.5;
}
.speedometer .round-param .round-buttons .button-wrapper.active {
  opacity: 1;
}
.speedometer .round-param .round-buttons ion-button {
  --border-width: 1px;
  border-radius: 50%;
  height: 30px;
  margin: 0 0.3rem;
  margin-bottom: 0.3rem;
  width: 30px;
}
.speedometer .round-param .round-buttons ion-text {
  font-size: 0.8rem;
  font-weight: 500;
}
.speedometer .round-param .speedometer-progress-bar {
  height: 152px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 152px;
  z-index: 1;
}
.speedometer .round-param .speedometer-progress-bar .rotating-progress-bar {
  transform: rotate(var(--rotate-to));
  transform-origin: center;
  transition-property: transform;
  will-change: transform;
}
.speedometer .round-param .speedometer-progress-bar .rotate-progress-bar {
  animation-duration: 1200ms;
  animation-iteration-count: 1;
  animation-name: rotate;
  animation-timing-function: ease-out;
}
.speedometer .round-param .speedometer-progress-bar .hide-progress-tail {
  opacity: 0;
  transition: opacity 400ms ease-out;
}

@keyframes rotate {
  from {
    transform: rotate(var(--rotate-from));
  }
  to {
    transform: rotate(var(--rotate-to));
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-flow: row nowrap;
}

@media (max-width: 450px) {
  .speedometer .round-param .no-data {
    font-size: 0.9rem;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
