// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  --background: var(--ion-color-light-warm);
  display: flex;
  min-height: 80px;
}
.header-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header-row .filter {
  --padding-start: 0.5rem;
  --padding-end: 0.5rem;
}
.header-row .filter ion-icon {
  font-size: 25px;
}

.list {
  padding: 0 12px;
  background: var(--ion-color-light-warm);
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}
.list-item {
  --background: transparent;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  margin-top: 55px;
  padding: 4px 12px;
}
.list-item app-car-card {
  width: 100%;
}
.list-item.active {
  background-color: var(--ion-color-light-grey);
  border-radius: 15px;
}
.list-item .card {
  width: 100%;
}

.drawer {
  margin-top: env(safe-area-inset-top);
  margin-top: constant(safe-area-inset-top);
  top: -60px;
  background: transparent;
}
@supports (padding-top: env(safe-area-inset-bottom)) {
  .drawer.ios {
    top: calc(-14vh - env(safe-area-inset-bottom));
  }
}
.drawer-opened {
  top: 0 !important;
}
.drawer-content {
  background: transparent;
  height: 100%;
}
.drawer-content-non-transparent {
  background-color: var(--ion-color-light-warm);
}
.drawer-content-slides {
  padding-top: 48px;
}
.drawer-content-slides ion-slide .car-card {
  width: 100%;
}
.drawer-content-scroll {
  background-color: var(--ion-color-primary-contrast);
  border-bottom: 2px solid var(--ion-color-light-warm);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
.drawer-content-scroll-header ion-title {
  padding-inline: 0;
}
.drawer-content-scroll-header ion-title .top-bar {
  display: flex;
}
.drawer-content-scroll-list {
  background-color: var(--ion-color-light-warm);
  height: 100vh;
  overflow-y: auto;
  padding: 12px;
  padding-bottom: 150px;
  text-align: center;
}
.drawer-content-scroll-list-item {
  margin-top: 70px;
}
.drawer-content-scroll .pullup-wrapper {
  display: flex;
  justify-content: center;
}
.drawer-content-scroll .pullup-wrapper .pullup-icon {
  background-color: var(--ion-color-medium-tint);
  border-radius: 5px;
  display: block;
  height: 5px;
  margin: 10px;
  width: 40px;
}
.drawer-content-scroll .slide-up-block {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
.drawer-content-scroll .slide-up-block .slide-up {
  background-color: #000;
  border-radius: 15px;
  height: 5px;
  width: 50px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
