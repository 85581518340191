// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.dealer-card {
  --background: var(--ion-color-primary-contrast);
  border-radius: 15px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.25rem auto;
  padding: 1rem;
}
.dealer-card.in-slider {
  margin: 0;
  transition: background ease 0.25s;
}
.dealer-card.not-clickable {
  cursor: auto;
}
.dealer-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 70px;
  padding: 0;
}
.dealer-card-content-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dealer-card-content-data .direction {
  display: inline;
  background-color: var(--ion-color-light-tint);
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 0.8rem;
}
.dealer-card-content-data .description {
  align-items: flex-start;
  display: flex;
  flex: 2;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  margin-top: 10px;
}
.dealer-card-content-data .description ion-text {
  text-align: left;
  width: 100%;
}
.dealer-card-content-data .description-phone-and-count-card {
  align-items: flex-end;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
}
.dealer-card-content-data .description-title {
  color: var(--ion-color-dark);
  font-size: 1rem;
  font-weight: 500;
}
.dealer-card-content-data .description-address {
  font-size: 0.9rem;
}
.dealer-card-content-data .description-phone-button {
  --padding-start: 0;
  pointer-events: auto;
  font-weight: 400;
  margin: 0;
}
.dealer-card-content-data .connections {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.dealer-card-content-data .connections-empty {
  justify-content: flex-end;
}
.dealer-card-content-data .connections-cars {
  align-items: center;
  color: #000;
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  justify-content: space-between;
  transform: translateY(2px);
}
.dealer-card-content-data .connections-cars-icon {
  font-size: 1.3rem;
  margin-right: 0.5rem;
  pointer-events: none;
}
.dealer-card-content-buttons {
  display: flex;
  justify-content: flex-end;
}
.dealer-card-content-buttons .connect,
.dealer-card-content-buttons .details {
  font-weight: 400;
  outline: none;
  z-index: 10; /* для кликабельности в форме выбора дилера */
}
.dealer-card-content-buttons .connect {
  --background: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
}
.dealer-card-content-buttons .open-navigator {
  --background: var(--ion-color-primary);
  --color: white;
}
.in-slider .dealer-card-content {
  height: 180px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
