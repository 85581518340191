// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background: var(--ion-color-brand);
  padding: 0.6rem;
}
.header ion-segment {
  margin: 0;
}
.header ion-segment.notifications-categories {
  --background: var(--ion-color-dark);
  color: white;
}

.notifications {
  --background: var(--ion-color-brand);
}
.notifications-refresher {
  z-index: 1;
}
.notifications ion-infinite-scroll {
  padding-bottom: 3rem;
}
.notifications ion-infinite-scroll ion-infinite-scroll-content {
  --color: white;
}
.notifications-scroll {
  background: var(--ion-color-brand);
  margin-bottom: 1.5rem;
}
.notifications-scroll-item {
  --padding-start: 12px;
  --padding-end: 12px;
  --inner-padding-end: 0;
  --min-height: 0;
  --background: var(--ion-color-brand);
  --color: white;
  --inner-padding-top: 0.3rem;
  --inner-padding-bottom: 0.3rem;
}
.notifications-not-found {
  color: white;
  display: block;
  margin-top: 20px;
  text-align: center;
}
.notifications .notification {
  display: flex;
  width: 100%;
}

.desktop-header {
  --background: var(--ion-color-brand);
}
.desktop-header .notifications-categories {
  background: var(--ion-color-dark);
}
.desktop-header .notifications-categories .category {
  --color-checked: #fff;
  --background: var(--ion-color-brand);
}

.desktop-notifications {
  --background: transparent;
  height: 70vh;
}
.desktop-notifications .notifications-not-found {
  color: #fff;
}
.desktop-notifications .notifications-scroll-item {
  --background: transparent;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
