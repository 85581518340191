// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-toolbar {
  --background: var(--ion-color-light-warm);
}
ion-header ion-toolbar ion-title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  font-weight: 500;
  padding-inline: 50px;
}
ion-header ion-toolbar.filters ion-segment {
  width: 100%;
}

ion-content {
  --background: var(--ion-color-light-warm);
}
ion-content .current-list,
ion-content .history-list {
  background: var(--ion-color-light-warm) !important;
}
ion-content .current-list-item,
ion-content .history-list-item {
  --background: transparent;
  margin-bottom: 0.5rem;
}
ion-content .current-list-item app-error-card,
ion-content .history-list-item app-error-card {
  width: 100%;
}
ion-content .empty-wrapper {
  text-align: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
