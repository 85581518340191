// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-card {
  --background: var(--ion-color-primary-contrast);
  border-radius: 10px;
  box-shadow: none;
  margin: 0;
  padding: 0.5rem 1rem;
  cursor: default;
}
.error-card-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  max-width: 100%;
}
.error-card-content-title {
  margin: 0;
  padding: 0;
}
.error-card-content-title ion-text > * {
  font-size: 1rem;
  font-weight: 500;
}
.error-card-content-text {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
