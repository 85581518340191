// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.car-card {
  background: var(--ion-color-primary-contrast);
  border-radius: 15px;
  box-shadow: none;
  cursor: pointer;
  display: block;
  margin: 0.5rem auto;
  overflow: visible;
  padding: 1rem 0.5rem 0.5rem 1rem;
  position: relative;
}
.car-card.not-clickable {
  cursor: auto;
}
.car-card .car-state-number {
  display: flex;
  margin: 10px 0;
}
.car-card .layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.car-card .layout-left-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 0.2rem;
}
.car-card .layout-right-content {
  display: flex;
  flex-direction: column;
  min-width: 160px;
  position: relative;
  top: calc(-140px * 0.4 - 1rem);
  margin-bottom: calc(-140px * 0.4 - 1rem);
}
.car-card .layout-right-content .img-wrapper {
  align-items: center;
  display: flex;
  flex: 0 1 130px;
  height: 140px;
  justify-content: center;
  margin: 0 auto;
  max-height: 140px;
  max-width: 120px;
  width: 120px;
}
.car-card .layout-right-content .img-wrapper-img {
  object-fit: contain;
}
.car-card .layout-right-content .img-wrapper-img-empty {
  align-items: center;
  background: var(--ion-color-light-shade);
  border-radius: 10px;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}
.car-card .layout-right-content .img-wrapper-img-empty ion-icon {
  font-size: 5rem;
  opacity: 0.7;
}
.car-card .layout-right-content .details {
  font-weight: 400;
  outline: none;
  transform: translateY(5px);
}
.car-card .layout-right-content .car-status {
  color: #adebc5;
  margin: 10px 0;
  text-align: center;
}
.car-card .layout-right-content .updated-at {
  font-size: 0.6rem;
  text-align: center;
}
.car-card .layout-right-content .features {
  color: var(--ion-color-dark);
  display: flex;
  justify-content: space-around;
}
.car-card .layout-right-content .features .feature {
  align-items: center;
  display: flex;
  font-weight: bold;
}
.car-card .layout-right-content .features .feature-value {
  font-size: 1.1rem;
}
.car-card .layout-right-content .features .feature-icon {
  margin: 0 2.5px;
}
.car-card.next-slide {
  --background: var(--ion-color-medium-tint);
  transition: all ease 0.5s;
}
.car-card .car-model {
  font-size: 0.8rem;
}
.car-card .address {
  font-size: 0.7rem;
  margin-top: auto;
}
.car-card .pullup-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.car-card .pullup-wrapper .pullup-icon {
  background-color: var(--ion-color-light-shade);
  border-radius: 5px;
  display: block;
  height: 5px;
  margin-right: 15px;
  width: 40px;
}
.car-card .car-card-content {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  text-align: left;
  overflow-y: visible;
}
.car-card .car-card-content .car-card-title {
  margin: 0;
  padding: 0;
}
.car-card .car-card-content .car-card-title-text > * {
  font-size: 1rem;
  font-weight: 500;
}
.car-card .car-card-content .car-card-text {
  font-size: 0.9rem;
  font-weight: 500;
}
.car-card .car-card-content .number-icons-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin: 10px 0 0;
}
.car-card .car-card-content .number-icons-wrapper .icons-wrapper {
  padding-left: 5px;
}
.car-card .car-card-content .subs-status {
  color: var(--ion-color-danger);
  font-size: 1.2rem;
  font-weight: 500;
}
.car-card .car-card-content .rnis-id-wrapper {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  font-size: 11px;
  padding-top: 3px;
  pointer-events: none;
}
.car-card .car-card-content .rnis-id-wrapper .rnis-copy-button {
  --min-height: 20px;
  --padding-end: 0;
  pointer-events: auto;
  height: 20px;
  margin: 0;
}
.car-card .car-card-content .rnis-id-wrapper .rnis-copy-button-icon {
  height: 20px;
  width: 24px;
}
.car-card.as-checkbox-label {
  height: auto;
  min-height: 144px;
}
.car-card.as-checkbox-label .car-card-content .layout-right-content {
  margin-bottom: 0;
  min-width: 100px;
  top: 0;
}
.car-card.as-checkbox-label .car-card-content .layout-right-content .img-wrapper {
  height: 110px;
  max-height: 110px;
  max-width: 100px;
  width: 100px;
}

@media (max-width: 1199px) {
  .car-card {
    height: 180px;
  }
}
@media (max-width: 576px) {
  .layout-left-content {
    margin-right: 0;
  }
  .layout-right-content {
    min-width: 143px;
  }
  .layout-right-content .features {
    font-size: 0.7rem;
  }
  .layout-right-content .features .feature .feature-value {
    font-size: 1rem;
  }
  .layout-right-content .features .updated-at {
    font-size: 0.5rem;
  }
}
@media (max-width: 576px) and (max-width: 380px) {
  .layout-left-content .rnis-id-wrapper {
    font-size: 9px;
  }
  .layout-right-content {
    min-width: 80px;
  }
  .layout-right-content .under-image-content {
    width: max-content;
    margin: 0 auto;
    text-align: left;
  }
  .layout-right-content .under-image-content .car-status {
    margin: 5px 0 !important;
    font-size: 0.9rem;
  }
  .layout-right-content .under-image-content .features {
    display: block;
    margin: 0 auto;
    width: max-content;
    flex-direction: column;
    align-items: center;
  }
  .layout-right-content .under-image-content .updated-at {
    text-align: left;
  }
  .layout-right-content .img-wrapper {
    height: 90px;
    max-height: 90px;
    min-width: 80px;
    width: 80px;
  }
}
@media (max-width: 576px) {
  .car-card-title ion-text > * {
    font-size: 0.9rem !important;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
