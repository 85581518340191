// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --height: 100%;
  --background-color: transparent;
}

.content {
  height: var(--height);
  overflow-y: visible;
}
.content .cars-list {
  --background: var(--background-color);
}
.content .cars-list ion-item {
  --background: var(--background-color);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
