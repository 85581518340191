// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-back-button {
  display: block;
}
ion-header ion-title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
  font-weight: bold;
}

ion-content .no-sts-cars-label {
  display: block;
}
ion-content .content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 30px;
}
ion-content .content-wrapper .car-item {
  display: flex;
  flex-direction: column;
  --padding-start: 0;
}
ion-content .content-wrapper .car-item ion-label {
  margin: 10px 0;
  font-size: 1.2rem;
  font-weight: bold;
}
ion-content .content-wrapper .submit-button {
  --border-radius: 20px;
  --box-shadow: none;
  margin-bottom: 10px;
  text-transform: none;
  font-weight: 400;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
