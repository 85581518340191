// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.flex-column {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.param {
  cursor: pointer;
  flex: 1 1 50%;
  position: relative;
}
.param--locked {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.param--inner > .icon {
  height: 32px;
  margin-bottom: 10px;
  min-height: 32px;
  min-width: 32px;
  width: 32px;
}
.param--inner > *:not(:first-child) {
  margin-top: 5px;
}
.param .value,
.param .unit {
  opacity: 0.3;
}

.small {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
}

.very-small {
  font-size: 11px;
}

@media (min-width: 1400px) {
  .param {
    margin-top: 1rem;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
