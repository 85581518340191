// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  --background: var(--ion-color-primary-contrast);
  border-radius: initial;
  border-radius: 15px;
  box-shadow: none;
  display: flex;
  flex-flow: row no-wrap;
  min-height: 170px;
  outline: none;
  padding: 1rem;
  position: relative;
}
ion-card ion-card-content {
  display: flex;
  flex-direction: column;
}
ion-card ion-card-content .top-content {
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: space-between;
  padding: 0;
}
ion-card ion-card-content .bottom-content {
  margin-top: 10px;
}
ion-card ion-card-content .bottom-content .tag {
  padding: 3px 6px;
  border: 1px solid var(--ion-color-danger);
  color: var(--ion-color-danger);
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.8rem;
}
ion-card ion-card-content ion-card-title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
}
ion-card ion-card-content ion-text {
  font-size: 0.8rem;
}
ion-card ion-card-content .img-wrapper {
  align-items: center;
  display: flex;
  flex: 0 0 120px;
  justify-content: center;
  margin-left: 1rem;
}
ion-card ion-card-content .img-wrapper .img {
  max-height: 100px;
  object-fit: cover;
  width: 80%;
}
ion-card ion-card-content .img-wrapper .img-empty {
  align-items: center;
  background: var(--ion-color-light-warm);
  display: flex;
  justify-content: center;
  max-height: 100px;
  position: relative;
  width: 80%;
}
ion-card ion-card-content .img-wrapper .img-empty ion-icon {
  font-size: 5rem;
  opacity: 0.7;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
