// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  position: relative;
}
.content .disable-onboarding-button {
  --background: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: none;
  z-index: 10;
}
.content .swiper {
  height: 100%;
  width: 100%;
}
.content .swiper-slide {
  align-items: flex-start;
}
.content .swiper ng-component {
  width: 100%;
  height: 100%;
}
.content .swiper ng-component .template {
  height: 100%;
}
.content .swiper ng-component .template .image-wrapper {
  height: 50%;
}
.content .swiper ng-component .template .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .swiper ng-component .template .text {
  text-align: left;
  padding: 1.5rem;
}
.content .swiper ng-component .template-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content .swiper ng-component .template-1 .text {
  text-align: center;
}
.content .swiper ng-component .template-4 {
  margin-top: 2.5rem;
}
.content .swiper ng-component .template-4 .text {
  height: 50%;
}
.content .swiper ng-component .template-3 .images {
  display: flex;
  height: 50%;
}
.content .swiper ng-component .template-3 .images .image-wrapper {
  margin: 0 0.5rem;
  height: 100%;
}
.content .swiper ng-component .template-3 .texts {
  display: flex;
  margin: 1rem 0.5rem;
}
.content .swiper ng-component .template-3 .texts .text {
  padding: 0;
  width: 50%;
}
.content .swiper ng-component .template-2 .images {
  display: flex;
  height: 50%;
}
.content .swiper ng-component .template-2 .images .image-wrapper {
  margin: 0;
  height: 100%;
}
.content .swiper ng-component .template-2 .images .image-wrapper:nth-child(1) {
  margin-right: 0.25rem;
}
.content .swiper ng-component .template-2 .images .image-wrapper:nth-child(2) {
  margin-left: 0.25rem;
}

.footer .swiper-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .swiper-controls .pagination {
  display: flex;
  align-items: center;
}
.footer .swiper-controls .pagination-bullet {
  cursor: pointer;
  width: 40px;
  height: 3px;
  background-color: var(--ion-color-medium-tint);
  margin: 0 5px;
}
.footer .swiper-controls .pagination-bullet-active {
  cursor: pointer;
  width: 40px;
  height: 3px;
  margin: 0 5px;
  background-color: var(--ion-color-primary-shade);
}
.footer .swiper-controls .clear-button {
  --ripple-color: transparent;
  --background-hover: transparent;
  pointer-events: none;
}
.footer .swiper-controls .prev-button {
  text-transform: none;
  color: black;
  min-width: 70px;
}
.footer .swiper-controls .next-button {
  text-transform: none;
  color: var(--ion-color-primary-shade);
  min-width: 70px;
}

@media (max-width: 1199px) {
  .content {
    --background: var(--ion-color-brand);
  }
  .content .mobile-prev-next-buttons {
    position: absolute;
    top: 0;
    display: flex;
    z-index: 10;
    width: 100%;
    justify-content: space-between;
    padding: 1rem;
  }
  .content .mobile-prev-next-buttons .clear-button {
    --ripple-color: transparent;
    --background-hover: transparent;
  }
  .content .mobile-prev-next-buttons .button {
    text-transform: none;
    color: white;
  }
  .content .swiper ng-component .template {
    background-color: var(--ion-color-brand);
    color: white;
  }
  .content .swiper ng-component .template .image-wrapper {
    background: linear-gradient(180deg, rgba(44, 53, 65, 0) -8.74%, var(--ion-color-brand) 62.62%);
    width: 95%;
    margin: 1rem auto 0;
  }
  .content .swiper ng-component .template .image-wrapper img {
    filter: opacity(0.6);
  }
  .footer {
    padding: 1rem;
    background-color: var(--ion-color-brand);
  }
  .footer .swiper-controls .pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
  .footer .swiper-controls .pagination-bullet-active {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--ion-color-primary);
  }
  .footer .complete-button {
    width: 100%;
    text-transform: none;
    margin-top: 2rem;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
