// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 auto;
  padding-bottom: 2.5rem;
}
.content-head {
  padding-left: 1.5rem;
  --background: var(--ion-color-light-warm);
}
@media only screen and (min-width: 768px) and (min-height: 600px) {
  .content-head {
    margin-top: 1.25rem;
    --background: default;
  }
}
.content-head-back-button {
  display: block;
  margin-left: -0.25rem;
}
.content-head-title {
  margin-left: -4.75rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.25rem;
}
@media only screen and (min-width: 768px) and (min-height: 600px) {
  .content-head-title {
    font-weight: 700;
    margin-left: -1.75rem;
    font-size: 1.5rem;
  }
}
.content-form {
  width: 100%;
  max-width: 400px;
  height: 100%;
  text-align: center;
  padding: 0 2.5rem;
}
.content-form ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}
.content-form ion-item.loader-wrapper ion-spinner {
  margin: 0 auto;
}
.content-form ion-item.input-wrapper {
  margin-bottom: 0.5rem;
}
.content-form ion-item.input-wrapper:first-of-type {
  margin-top: 1rem;
}
.content-form ion-item.input-wrapper:last-of-type {
  margin-bottom: 1.875rem;
}
.content-form ion-item.input-wrapper ion-input {
  font-size: 0.95rem;
}
.content-form ion-item.input-wrapper ion-input::after {
  display: block;
  content: "";
  background-image: url("/assets/imgs/edit_pencil.svg");
  background-size: 1rem 1rem;
  height: 1rem;
  width: 1rem;
}
.content-form ion-item.input-wrapper ion-textarea {
  margin-bottom: 0.25rem;
  --padding-end: 1rem;
  font-size: 0.95rem;
}
.content-form ion-item.input-wrapper ion-textarea::after {
  display: block;
  content: "";
  background-image: url("/assets/imgs/edit_pencil.svg");
  background-size: 1rem 1rem;
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: 0.7rem;
  right: 0;
}
.content-form ion-item.checkbox-wrapper ion-label {
  min-width: 320px;
  white-space: normal;
  font-size: 0.875rem;
}
.content-form ion-item.checkbox-wrapper ion-checkbox {
  margin: auto;
  margin-right: 0.625rem;
  --inner-border-style: none;
}
.content-form ion-item.payment-rules-wrapper {
  padding: 0.625rem 1.25rem;
  background-color: var(--ion-color-light-warm);
  border-radius: 5px;
  margin-top: 1rem;
}
.content-form ion-item.payment-rules-wrapper ion-text {
  font-size: 0.7rem;
  background-color: var(--ion-color-light-warm);
}
.content-form .payment-amounts {
  width: 100%;
  margin-top: 1.875rem;
}
.content-form .payment-amounts hr {
  width: 100%;
  height: 1px;
  margin: 1.25rem auto;
  background-color: #c6cbd4;
}
.content-form .payment-amounts-block {
  display: flex;
  justify-content: space-between;
}
.content-form .payment-amounts-block.amount {
  color: #4f4f4f;
  margin-bottom: 1rem;
  font-weight: 600;
}
.content-form .payment-amounts-block.discount {
  margin-bottom: 1rem;
  color: #038a00;
  font-weight: 600;
}
.content-form .payment-amounts-block.fee {
  color: #1b2731;
  font-weight: 600;
}
.content-form .payment-amounts-block.total-amount {
  font-size: 1.125rem;
  color: #4f4f4f;
  font-weight: 700;
}
.content-form .submit-button {
  --border-radius: 4px;
  font-weight: 400;
  width: 10rem;
  height: 2.25rem;
  margin-top: 2.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
