// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.penalty-stat-card {
  margin: 0;
  box-shadow: none;
  color: #000;
  --background: var(--ion-color-primary-contrast);
}
.penalty-stat-card .content {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.penalty-stat-card .content-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}
.penalty-stat-card .content-left .bottom-text {
  font-size: 0.8rem;
}
.penalty-stat-card .content-left-title {
  font-size: 1rem;
}
.penalty-stat-card .content-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  text-align: right;
}
.penalty-stat-card .content-right .penalty-property {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 576px) {
  .penalty-stat-card .content-right .penalty-property {
    justify-content: flex-end;
  }
}
.penalty-stat-card .content-right .penalty-property .title {
  font-size: 0.8rem;
}
@media (min-width: 576px) {
  .penalty-stat-card .content-right .penalty-property .title {
    text-align: left;
    min-width: 8rem;
  }
}
.penalty-stat-card .content-right .penalty-property .value {
  margin-left: 5px;
}
@media (min-width: 576px) {
  .penalty-stat-card .content-right .penalty-property .value {
    min-width: 8rem;
  }
}
.penalty-stat-card .content-right .penalty-property .value-date-connection {
  font-size: 0.8rem;
}
.penalty-stat-card .content-right .penalty-property .count,
.penalty-stat-card .content-right .penalty-property .amount {
  font-size: 1rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
