// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
p.sms-code-p {
  color: var(--ion-color-primary-contrast);
  margin-top: 0;
}

.verify-code-form ion-item {
  --background: transparent;
  --background-focused: transparent;
  --border-color: var(--ion-color-medium);
  --padding-start: 0;
  --padding-end: 0;
}
.verify-code-form ion-input {
  --color: var(--ion-color-primary-contrast);
  --placeholder-opacity: 0.3;
}

.input {
  align-items: bottom;
  color: var(--ion-color-primary-contrast);
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 0;
  width: 50%;
}
.input__item {
  flex: 1 1 25%;
  font-size: 50px;
  margin: 0;
  min-width: 50px;
  position: relative;
}
.input__item--empty::after {
  align-items: center;
  content: "•";
  display: flex;
  font-size: 50px;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.input__item .text-input {
  text-align: center;
}

.submit-button {
  --opacity: 1;
  --background-hover: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  --color: var(--ion-color-light);
}

.resend {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  justify-content: center;
}
.resend__button {
  font-size: 10px !important;
  height: 10px !important;
}

.numpad {
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 280px;
}
.numpad__block {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: -10px;
}
.numpad__button {
  align-items: center;
  display: flex;
  font-size: 30px !important;
  font-weight: 500 !important;
  height: 60px !important;
  justify-content: center;
  line-height: 62px;
  margin: 0 auto;
  padding: 0;
  width: 60px !important;
}
.numpad__button--wrapper {
  align-content: center;
  display: flex;
  flex: 1 0 33%;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  max-width: calc(33.33% - 20px);
}
.numpad__delete {
  font-size: 1.5rem;
  font-weight: 400;
  height: 60px !important;
  margin: 0 auto;
  opacity: 0.5;
  padding: 0;
}

.loading-indicator,
.loading-indicator * {
  box-sizing: border-box;
}

.loading-indicator .loading-wrapper {
  background-color: transparent;
  box-shadow: none;
}

.loading-indicator span {
  background: rgb(90, 174, 216);
  background-clip: padding-box;
  border-radius: 487px;
  display: inline-block;
  height: 15px;
  width: 15px;
}

.loading-indicator span:nth-child(1) {
  animation: scale 1.15s 0.12s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.loading-indicator span:nth-child(2) {
  animation: scale 1.15s 0.23s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.loading-indicator span:nth-child(3) {
  animation: scale 1.15s 0.35s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.loading-indicator span:nth-child(4) {
  animation: scale 1.15s 0.46s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.loading-indicator span:nth-child(5) {
  animation: scale 1.15s 0.58s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  25% {
    background: rgb(90, 174, 216);
    transform: scale(0.9, 0.9);
  }
  50% {
    background: rgb(37, 43, 51);
    margin: 0 3px;
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0);
  }
}
@media (min-width: 1140px) {
  .component--message {
    font-size: 0.9rem;
    opacity: 0.4;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
