// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  height: 100%;
  width: 100%;
}

.map {
  position: relative;
}
.map .loading {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  font-size: 1.5rem;
  transition: all ease 0.5s;
  text-align: center;
}
.map .loading ion-spinner {
  --color: white;
  transform: scale(2);
  margin-bottom: 2rem;
}
.map .route-info-wrapper {
  font-size: 0.9rem;
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: auto;
  height: auto;
  right: 10px;
  top: 40%;
  padding: 10px;
  border-radius: 0.7rem;
  min-width: 100px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
}
.map .route-info-wrapper .route-info {
  display: flex;
  flex-direction: column;
}
.map .route-info-wrapper .route-info .title {
  font-weight: bold;
}
.map .route-info-wrapper .reset-route {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  text-transform: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
