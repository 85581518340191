// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-placeholder {
  align-items: center;
  background-color: lightgrey;
  display: flex;
  height: 200px;
  justify-content: center;
  width: 100%;
}

.item {
  height: 200px;
  margin: 0 -10px;
  width: 270px;
}
.item__img {
  display: block;
  height: 100%;
  margin: 0 10px;
  object-fit: cover;
  width: 100%;
}

.component-buttons {
  bottom: 40px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  position: absolute;
  right: 40px;
  width: 88px;
  z-index: 1000;
}
.component-button {
  --padding-start: 0.5rem;
  --padding-end: 0.5rem;
  --color: var(--ion-color-dark);
  --background: var(--ion-color-white);
  --background-hover: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --border-style: none;
  --border-width: 0;
  --box-shadow: none;
  --border-radius: 100%;
  height: 2.4rem;
  margin: 0;
}
.component-button ion-icon {
  font-size: 1.4rem;
}

@media (min-width: 1200px) {
  .component-wrapper {
    height: 473px;
    position: relative;
    width: 760px;
  }
  .item {
    height: 473px;
    width: 760px;
  }
  .item__img {
    display: block;
    height: 473px;
    margin: 0 10px;
    object-fit: cover;
    width: 760px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
