// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header ion-back-button {
  display: block;
}
.header .header-title {
  position: initial;
  padding: 0;
  font-size: 1rem;
}

.check-vin-form {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.check-vin-form ion-input {
  margin-top: 20px;
}
.check-vin-form .enter-vin-label {
  font-size: 1.1rem;
}
.check-vin-form .submit-vin-check {
  --opacity: 1;
  --background-hover: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  --color: #fff;
  margin-top: 20px;
}
.check-vin-form .message {
  border-radius: 5px;
  color: #000;
  margin-top: 15px;
  padding: 20px;
  text-align: center;
  transition: all ease 0.5s;
  width: 100%;
}
.check-vin-form .message .connected {
  color: #5aadd8;
}
.check-vin-form .message .not-connected {
  color: #e33b3b;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
