// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component {
  align-items: center;
  background-color: #eeedee;
  border-radius: 3px;
  color: black;
  display: flex;
  flex-flow: row nowrap;
  font-weight: 500;
  padding: 2.5px 5px;
  text-transform: uppercase;
  width: auto;
}
.component--white {
  background-color: white;
}
.component--small {
  transform: scale(0.7) translateX(-27px);
  width: 130px;
}
.component--taxi {
  background-color: #ffc100;
}
.component__left {
  display: flex;
  flex: 1 1 80%;
  flex-flow: row nowrap;
  margin-bottom: -4px;
  padding: 2px 0;
}
.component__left > *:not(:last-child) {
  margin-right: 0.6rem;
}
.component__right {
  align-items: center;
  display: flex;
  flex: 1 1 20%;
  flex-direction: column;
  line-height: 1rem;
  padding-left: 0.6rem;
}
.component__letter, .component__digit {
  font-size: 1.2rem;
  letter-spacing: 1px;
}
.component__region-code {
  font-size: 0.6rem;
  font-weight: 500;
  line-height: 0.6rem;
}
.component__country-code {
  font-size: 0.4rem;
  font-weight: 500;
  line-height: 0.4rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
