// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header ion-toolbar {
  --background: var(--ion-color-light-warm);
}
.header ion-toolbar ion-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1rem;
}
.header ion-toolbar ion-back-button {
  display: block;
}

ion-content {
  --background: var(--ion-color-light-warm);
}
ion-content .content {
  height: 100%;
  text-align: center;
}
ion-content .content .filter-groups {
  width: 100%;
}
ion-content .content .filter-groups .group .name-of-group {
  margin: 10px 15px;
  color: var(--ion-color-medium-shade);
  font-size: 0.9rem;
  text-align: left;
}
ion-content .content .filter-groups .group-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
ion-content .content .filter-groups .group-element.active {
  color: var(--ion-color-primary);
}
ion-content .content .filter-groups .group-element.active ion-icon {
  font-size: 1.3rem;
}

ion-footer {
  background: var(--ion-color-light-warm);
  text-align: center;
  padding: 10px 0;
}
ion-footer .select-button {
  --padding-start: 3rem;
  --padding-end: 3rem;
  --opacity: 1;
  --background-hover: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  --color: #fff;
  margin-top: auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
