// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-toolbar {
  --min-height: 100px;
}
ion-header ion-toolbar img {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-35%);
}

ion-content {
  --background: var(--ion-color-light-warm);
}
ion-content .header {
  padding: 2rem 2rem 1rem;
}
ion-content .header .code {
  font-size: 1.5rem;
  font-weight: 500;
}
ion-content .header .status {
  line-height: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
}
ion-content .description {
  background: #fff;
  border-radius: 5px;
  padding: 1rem 2rem;
  box-shadow: 0 -1px 10px lightgrey;
}
ion-content .description .subheader {
  font-weight: 500;
  height: 52px;
}
ion-content .description-text {
  font-size: 0.9rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
