// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list,
ion-item {
  --background: var(--ion-color-brand);
  --background-focused: var(--ion-color-brand-tint);
  --background-activated: var(--ion-color-brand-tint);
  --color: var(--ion-color-brand-contrast);
  --border-color: var(--ion-color-medium);
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  background: var(--ion-color-brand);
}

ion-input {
  --color: var(--ion-color-primary-contrast);
  --placeholder-opacity: 0.3;
}

.submit-button {
  --opacity: 1;
  --background-hover: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  --color: var(--ion-color-light);
}

.custom-button {
  --opacity: 1;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  margin-right: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
