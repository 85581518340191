// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-wrapper {
  margin: 0 0.5rem;
}
.content-wrapper h1 {
  font-size: 1.2rem;
  text-align: center;
}
.content-wrapper h2 {
  color: var(--ion-color-medium);
  font-size: 1rem;
  margin-bottom: 1rem;
}
.content-wrapper a {
  outline: none;
}
.content-wrapper p,
.content-wrapper ul,
.content-wrapper ol,
.content-wrapper span {
  color: var(--ion-color-black);
  font-size: 0.8rem;
  font-weight: 400;
}
.content-wrapper ol {
  counter-reset: item;
  padding-inline-start: 10px;
}
.content-wrapper ol > li {
  display: block;
  margin-bottom: 1rem;
}
.content-wrapper ol > li::before {
  content: counters(item, ".") ". ";
  counter-increment: item;
}
.content-wrapper ol .sequence {
  margin-top: 1rem;
  padding-left: 10px;
}
.content-wrapper ol .sequence div {
  margin-bottom: 1rem;
}
.content-wrapper ul {
  padding-inline-start: 30px;
}
.content-wrapper ul > li {
  margin-bottom: 0.25rem;
}
.content-wrapper ol:first-child,
.content-wrapper ul:first-child,
.content-wrapper li:first-child {
  margin-top: 1rem;
}
.content-wrapper > ol > li::before {
  font-weight: bold;
}
.content-wrapper p {
  text-indent: 2rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
