// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-toolbar {
  --background: var(--ion-color-light-warm);
}
ion-header ion-toolbar ion-back-button {
  display: block;
}
ion-header ion-toolbar ion-title {
  position: initial;
  padding: 0;
  font-size: 1rem;
}

ion-content .create-user-form .permissions-item-wrapper,
ion-content .create-user-form .description-item-wrapper {
  --padding-start: 0;
  --inner-padding-end: 0;
}
ion-content .create-user-form .permissions-item-wrapper .permissions-item,
ion-content .create-user-form .permissions-item-wrapper .description-item,
ion-content .create-user-form .description-item-wrapper .permissions-item,
ion-content .create-user-form .description-item-wrapper .description-item {
  width: 100%;
  background-color: var(--ion-color-light-tint);
  margin: 10px 0;
}
ion-content .create-user-form .permissions-item-wrapper .permissions-item .header-label,
ion-content .create-user-form .permissions-item-wrapper .description-item .header-label,
ion-content .create-user-form .description-item-wrapper .permissions-item .header-label,
ion-content .create-user-form .description-item-wrapper .description-item .header-label {
  font-weight: 500;
  font-size: 1rem;
}
ion-content .create-user-form .permissions-item-wrapper .permissions-item .permission-text,
ion-content .create-user-form .permissions-item-wrapper .description-item .permission-text,
ion-content .create-user-form .description-item-wrapper .permissions-item .permission-text,
ion-content .create-user-form .description-item-wrapper .description-item .permission-text {
  white-space: normal;
}
ion-content .create-user-form .permissions-item-wrapper .permissions-item .description-text,
ion-content .create-user-form .permissions-item-wrapper .description-item .description-text,
ion-content .create-user-form .description-item-wrapper .permissions-item .description-text,
ion-content .create-user-form .description-item-wrapper .description-item .description-text {
  white-space: pre-wrap;
}
ion-content .create-user-form ion-select {
  width: 100%;
}
ion-content .available-cars-label {
  margin-top: 1rem;
  margin-bottom: 10px;
}
ion-content .cars-selector {
  --background-color: var(--ion-color-light-tint);
}

ion-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0.5rem 0;
}
ion-footer .delete-user ion-icon {
  margin-right: 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
