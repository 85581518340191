// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.notification-content-wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--ion-color-custom-grey);
  padding: 1rem;
  border-radius: 1rem;
}
.notification-content-wrapper .notification-title {
  color: var(--ion-color-primary);
  margin-bottom: 0.6rem;
  font-size: 1.1rem;
  font-weight: 500;
}
.notification-content-wrapper .notification-text {
  font-size: 0.9rem;
}
.notification-date {
  color: var(--ion-color-medium);
  font-size: 1.1rem;
  margin-bottom: 0.6rem;
  text-align: left;
}
.notification.notification-desktop .notification-text {
  color: #fff;
}
.notification.dealer-notification .notification-content-wrapper {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
