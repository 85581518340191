// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
}

.blue {
  color: var(--ion-color-primary);
  cursor: pointer;
  opacity: 1;
}

.right {
  float: right;
  font-size: 12px;
}

.dealer {
  border-bottom: 1px solid #555;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
}
.dealer:first-child {
  border-top: 1px solid #555;
}
.dealer-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.dealer-address {
  font-size: 12px;
  opacity: 0.5;
}
.dealer .remove-wrapper ion-icon {
  cursor: pointer;
  font-size: 20px;
  padding: 0 5px 5px;
}

.add-dealer {
  height: 50px;
}
.add-dealer .text-wrapper {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
}
.add-dealer .text-wrapper .plus-wrapper {
  align-items: center;
  display: flex;
}
.add-dealer .text-wrapper .plus-wrapper ion-icon {
  font-size: 20px;
}
.add-dealer .text-wrapper .plus-wrapper ion-text {
  padding: 2px 0 0 10px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
