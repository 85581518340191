// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card.gaz-campus-card {
  --background: var(--ion-color-primary-contrast);
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 12px;
  min-height: 340px;
  outline: none;
  width: 250px;
}

ion-card-content.gaz-campus-card-content {
  background-color: var(--ion-color-primary-contrast);
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px 16px;
}

ion-card-title.gaz-campus-card-title {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.94rem;
  margin-bottom: 1rem;
  margin-top: 0;
  padding-top: 0;
  text-align: left;
}

time.gaz-campus-card-date {
  color: var(--ion-color-dark);
  font-size: 0.75rem;
  margin-bottom: 0;
  opacity: 0.6;
}

.text {
  font-size: 0.7rem;
  font-weight: 500;
}

.img {
  height: 200px;
  margin: 0;
  object-fit: cover;
  width: 100%;
}

.img-empty {
  align-items: center;
  background: var(--ion-color-light-warm);
  display: flex;
  height: 200px;
  justify-content: center;
  width: 100%;
}
.img-empty ion-icon {
  font-size: 5rem;
  opacity: 0.7;
}

.bottom {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1200px) {
  ion-card.gaz-campus-card {
    height: 355px;
    width: 420px;
  }
  ion-card-content.gaz-campus-card-content {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  ion-card-title.gaz-campus-card-title {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  time.gaz-campus-card-date {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
