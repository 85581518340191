// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-time-notifications-wrapper {
  background-color: var(--ion-color-custom-dark);
  border-radius: 10px;
  padding: 0 1.1rem 1.1rem;
}
.setting-time-notifications-wrapper ion-item {
  --background: transparent;
  color: #fff;
}
.setting-time-notifications-wrapper .time-start-end-wrapper {
  background-color: var(--ion-color-custom-grey);
  border-radius: 10px;
  margin: 0.6rem 0;
}
.setting-time-notifications-wrapper .time-start-end-wrapper ion-item {
  --min-height: 0;
  --inner-padding-end: 0;
}
.setting-time-notifications-wrapper .time-start-end-wrapper ion-item ion-button {
  font-size: 0.95rem;
  font-weight: 500;
  text-transform: none;
}
.setting-time-notifications-wrapper .setting-time-notifications-toggle {
  --padding-start: 0;
  --inner-padding-end: 0;
}
.setting-time-notifications-wrapper .setting-time-notifications-toggle ion-label {
  text-overflow: inherit;
  white-space: normal;
}
.setting-time-notifications-wrapper .user-timezone {
  font-size: 0.8rem;
  color: var(--ion-color-medium);
}

ion-segment.notifications-categories {
  --background: var(--ion-color-dark);
  margin: 1.2rem 0 0;
}

.notifications-settings {
  display: block;
  margin-top: 1.1rem;
  flex-direction: column;
  position: relative;
}
.notifications-settings .spinner-wrapper {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 10px;
}
.notifications-settings .spinner-wrapper ion-spinner {
  margin-top: 0.6rem;
}
.notifications-settings-wrapper {
  padding: 0.6rem 0;
  background-color: var(--ion-color-custom-dark);
  border-radius: 20px;
  margin-bottom: 0.6rem;
}
.notifications-settings .setting-2-level {
  zoom: 0.9;
}
.notifications-settings .setting-3-level {
  zoom: 0.8;
}
.notifications-settings .setting-4-level {
  zoom: 0.7;
}
.notifications-settings .setting-5-level {
  zoom: 0.6;
}
.notifications-settings .setting {
  --background: none;
  --color: #fff;
  --background-hover: none;
  --min-height: 0;
  --inner-padding-top: 5px;
  --inner-padding-bottom: 5px;
  --inner-padding-start: 0;
  --inner-padding-end: 10px;
  display: flex;
}
.notifications-settings .setting-title {
  margin: 0;
  margin-left: 0.6rem;
}
.notifications-settings .setting-title-active {
  color: var(--ion-color-medium);
}
.notifications-settings .setting-checkbox {
  --checkmark-color: var(--ion-color-custom-dark);
  --background: var(--ion-color-medium);
  margin: 0;
}
.notifications-settings .setting-checkbox::part(mark) {
  opacity: 1;
}
.notifications-settings .child-settings {
  padding-left: 1rem;
  margin-top: 0.6rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
