// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: rgb(18 25 47 / 88%);
}
ion-content .header {
  margin-top: env(safe-area-inset-top);
  margin-top: cosntant(safe-area-inset-top);
}
ion-content .header-toolbar {
  --background: transparent;
}
ion-content .header-toolbar .logo-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-content .header-toolbar .logo-wrapper .logo {
  width: 75px;
}
ion-content .header ion-back-button {
  display: block;
}
ion-content .current-month {
  display: block;
  color: white;
  margin: 1rem 0;
  font-weight: 500;
  font-size: 1.1rem;
  text-transform: uppercase;
}
ion-content .calendar {
  --background: transparent;
  color: white;
}
ion-content .selected-event-info {
  margin-top: 1rem;
}
ion-content .selected-event-info .img-wrapper {
  position: relative;
}
ion-content .selected-event-info .img-wrapper img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}
ion-content .selected-event-info .img-wrapper .date-and-time {
  position: absolute;
  left: -1rem;
  top: 40%;
  background-color: var(--ion-color-brand);
  padding: 1rem;
  color: var(--ion-color-custom-green);
  border-radius: 0 20px 20px 0;
}
ion-content .selected-event-info .text-info {
  margin-top: 1rem;
}
ion-content .selected-event-info .text-info-title {
  font-size: 1.2rem;
  line-height: 0;
  color: var(--ion-color-custom-green);
}
ion-content .selected-event-info .text-info .type-description {
  margin-top: 1rem;
  display: flex;
  align-items: start;
  color: white;
}
ion-content .selected-event-info .text-info .type-description .type {
  margin-right: 1rem;
  background-color: var(--ion-color-custom-orange);
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 12px;
}
ion-content .selected-event-info .button-wrapper .details-button {
  --box-shadow: none;
  margin: 1rem 0;
  color: black;
  text-transform: none;
  background-color: var(--ion-color-success-shade);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
