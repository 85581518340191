// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header ion-toolbar {
  --background: var(--ion-color-light-warm);
}
.header ion-toolbar ion-back-button {
  display: block;
}
.header ion-toolbar ion-title {
  position: initial;
  padding: 0;
  font-size: 1rem;
}

.content {
  --background: var(--ion-color-light-warm);
}
.content .waybill-form {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.content .waybill-form .section-title {
  margin: 0.5rem 0;
  text-align: center;
  font-weight: 500;
}
.content .waybill-form .buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  padding-bottom: 10px;
}
.content .waybill-form .buttons .button {
  --border-radius: 10px;
  text-transform: none;
}
.content .waybill-form .input-row {
  display: flex;
  align-items: center;
  margin: 5px 0;
  position: relative;
}
.content .waybill-form .input-row > ion-label {
  flex: 40%;
  text-align: right;
}
.content .waybill-form .input-row ion-input {
  --padding-top: 5px;
  --padding-bottom: 5px;
  flex: 60%;
  background: white;
  border-radius: 15px;
  margin-left: 15px;
  border-bottom: 2px solid transparent;
  text-align: center;
  min-height: 1.8rem;
}
.content .waybill-form .input-row ion-input .covered-value {
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  cursor: pointer;
}
.content .waybill-form .input-row ion-input > ion-label {
  width: 100%;
  white-space: nowrap;
  position: absolute;
  background: white;
  overflow: hidden;
  padding: 0 5px;
  border-radius: 15px;
  z-index: 1;
  text-overflow: ellipsis;
}
.content .waybill-form .input-row ion-input.ng-invalid {
  border-bottom: 2px solid var(--ion-color-danger);
}
.content .waybill-form .input-row ion-input .car-label {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.content .waybill-form .input-row ion-input .car-label > span {
  flex: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content .waybill-form .input-row ion-input .car-label .state-number {
  flex: 30%;
  transform: scale(0.8);
}
.content .waybill-form .input-row.selection-input-row ion-input {
  cursor: pointer;
}
.content .waybill-form .input-row.empty-lines-input .input-wrapper {
  flex: 63%;
}
.content .waybill-form .input-row.empty-lines-input ion-input {
  max-width: 100px;
}
.content .waybill-form .input-row.waybill-number-input > ion-label {
  flex: 70%;
}
.content .waybill-form .input-row.waybill-number-input ion-input {
  flex: 30%;
}
.content .waybill-form .input-row .plus-minus-buttons {
  display: flex;
  position: absolute;
  right: -30px;
  font-weight: 700;
}
.content .waybill-form .input-row .plus-minus-buttons .button {
  cursor: pointer;
}
.content .waybill-form .input-row .plus-minus-buttons .plus-button {
  margin-left: 10px;
}

ion-modal.modal-calendar {
  --height: auto;
  --border-radius: 10px;
  --width: 300px;
  margin: auto;
}
ion-modal.modal-calendar::part(backdrop) {
  --backdrop-opacity: 0.3;
}

ion-popover.calendar-popover {
  --width: auto;
}

@media (width <= 1199px) {
  .content {
    font-size: 0.9rem;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
