// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-header ion-toolbar ion-title {
  font-size: 1rem;
  font-weight: 500;
  left: 0;
  position: absolute;
  top: 0;
}

ion-toolbar,
ion-content {
  --background: var(--ion-color-brand);
  --color: var(--ion-color-brand-contrast);
}

ion-footer::after {
  background-image: none;
}
ion-footer ion-toolbar {
  --border-width: 0;
  border-width: 0;
}
ion-footer ion-toolbar .close-button {
  --opacity: 1;
  --background-hover: var(--ion-color-primary);
  --background-activated: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  --color: var(--ion-color-light);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
