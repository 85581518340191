// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --display-collapsed: block;
}

ion-item {
  --background: var(--ion-color-dark-blue);
  --background-focused: var(--ion-color-brand-tint);
  --background-activated: var(--ion-color-brand-tint);
  --color: var(--ion-color-brand-contrast);
  --height: 56px;
  overflow: visible;
  font-size: 0.9rem;
  font-weight: 500;
}
ion-item ion-label {
  display: var(--display-collapsed);
}
ion-item:hover {
  --background: var(--ion-color-dark-blue-active);
  cursor: pointer;
}
ion-item.active {
  --background: var(--ion-color-dark-blue-active);
}
ion-item.active::after {
  background-color: var(--ion-color-primary);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
  z-index: 1;
}
ion-item .item-has-updates {
  position: absolute;
  width: 10px;
  height: 10px;
  left: -3px;
  background: red;
  top: 12px;
  border-radius: 100%;
  border: 2px solid white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
