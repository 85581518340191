// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-text {
  font-size: 0.85rem;
  font-weight: 400;
}

a {
  font-weight: 500;
  outline: none;
  text-decoration: none;
}

ion-accordion {
  border-radius: 5px;
  margin-bottom: 10px;
}

ion-accordion.accordion-expanded ion-item[slot=header],
ion-accordion.accordion-expanding ion-item[slot=header] {
  --background: var(--ion-color-brand);
  --color: var(--ion-color-light);
}

ion-accordion.accordion-collapsed ion-item[slot=header],
ion-accordion.accordion-collapsing ion-item[slot=header],
ion-accordion.accordion-collapsed ion-item[slot=content],
ion-accordion.accordion-collapsing ion-item[slot=content] {
  --background: var(--ion-color-dark-blue);
  background-color: var(--ion-color-dark-blue);
  --color: var(--ion-color-primary);
}

ion-accordion [slot=content] {
  background-color: var(--ion-color-brand);
  --color: var(--ion-color-light);
}

ion-accordion.accordion-expanded ion-item[slot=header],
ion-accordion.accordion-expanding ion-item[slot=header],
ion-accordion.accordion-expanded [slot=content],
ion-accordion.accordion-expanding [slot=content] {
  background-color: var(--ion-color-dark-blue);
  --background: var(--ion-color-dark-blue);
}

ion-accordion.accordion-expanded .ion-accordion-toggle-icon,
ion-accordion.accordion-expanding .ion-accordion-toggle-icon {
  color: var(--ion-color-primary);
}

ion-accordion.accordion-collapsed .ion-accordion-toggle-icon,
ion-accordion.accordion-collapsing .ion-accordion-toggle-icon {
  color: var(--ion-color-light);
}

ion-accordion ion-item[slot=header] ion-label {
  text-overflow: clip;
  white-space: normal;
  line-height: 1.2rem;
}

.inner-accordion-group {
  padding: 0.8rem;
}
.inner-accordion-group .inner-accordion {
  margin-bottom: 0.6rem;
  border-radius: 10px;
  background-color: var(--ion-color-dark-blue);
}
.inner-accordion-group .inner-accordion ion-item[slot=header] {
  font-size: 0.8rem;
}
.inner-accordion-group .inner-accordion .ion-accordion-toggle-icon {
  display: none !important;
}
.inner-accordion-group .inner-accordion.accordion-expanded ion-item[slot=header],
.inner-accordion-group .inner-accordion.accordion-expanding ion-item[slot=header] {
  --color: var(--ion-color-primary);
  font-weight: 500;
}
.inner-accordion-group .inner-accordion.accordion-expanded ion-item[slot=header],
.inner-accordion-group .inner-accordion.accordion-expanding ion-item[slot=header],
.inner-accordion-group .inner-accordion.accordion-expanded [slot=content],
.inner-accordion-group .inner-accordion.accordion-expanding [slot=content] {
  background-color: var(--ion-color-custom-grey);
  --background: var(--ion-color-custom-grey);
}

.support {
  margin: 0 1rem;
}
.support-title {
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
}
.support-phone {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}
.support-phone-icon {
  margin-right: 1.5rem;
  font-size: 1.5rem;
}
.support-phone-value {
  margin-top: 0.2rem;
  font-size: 1.2rem;
}
.support-email {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}
.support-email-icon {
  margin-top: 0.5rem;
  margin-right: 1.5rem;
  font-size: 1.5rem;
}
.support-email-value {
  margin-top: 0.2rem;
}
.support-chatbot {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-left: 3rem;
}
.support-chatbot-title {
  margin-right: 1rem;
}
.support-chatbot-link {
  margin-right: 1rem;
}
.support-faq-accordion-wrapper {
  margin: 0 auto;
}
.support-faq-accordion-wrapper ion-accordion {
  background-color: var(--ion-color-brand);
}
.support-faq-answer-content {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}
.support-faq-answer-content-text {
  font-weight: 300;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
