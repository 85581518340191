// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  padding: 0 1.1rem;
  background-color: var(--ion-color-custom-dark);
  border-radius: 10px;
}
.wrapper .ad-agreement-toggle-item {
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --background: var(--ion-color-custom-dark);
  --color-activated: white;
  --color-hover: white;
  color: var(--ion-color-white);
  display: flex;
  align-items: center;
  margin: 0.6rem 0;
}
.wrapper .ad-agreement-label ion-text {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
